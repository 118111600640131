import React from "react";

import Banner from "../../PagesComponents/Banner/Banner";
import TextBox from "../../PagesComponents/TextBox/TextBox";
import Cert from "../../PagesComponents/Cert/Cert";
import SpaceRight from "../../PagesComponents/SpaceRightS/SpaceRight";

import banner from "../../../assets/sustentability/bannerEn.webp";
import product from "../../../assets/sustentability/productos_sustentables.webp";
import silla from "../../../assets/sustentability/silla_eco.png";

import certs from "../../../certsEn.json";

import "./Sustentability.css";

export default function SustentabilityEn() {
  return (
    <div>
      <Banner img={banner} />

      <div className="sustentability-container">
        <TextBox
          text1="For almost 40 years in the market, we have achieved quality distinctions of a high level."
          text2="This motivates us to continue being a competitive brand at the national level."
          color="#FFF"
          colorletter="#000"
        />

        <section className="section-cert">
          {certs.map(({ name, image, info }) => (
            <Cert key={name} name={name} image={image} info={info} />
          ))}
        </section>
        <SpaceRight
          name={"Sustainable Products"}
          image={product}
          info={
            "When you purchase products from Grupo Requiez, you are also contributing to environmental conservation."
          }
        />
        <section className="section-info-p">
          <div className="section-info-p_left">
            <p>
              You help create a more just and sustainable world, avoiding
              deforestation, as a percentage of our products are certified with
              FSC (Forest Stewardship Council).
              <br />
              <br />
              You contribute to making your home healthier and more harmonious
              by replacing elements that are harmful to health. By using
              upholstery and textiles sourced from ecological plantations
              without chemical treatments, as well as being 100% recyclable and
              made from recycled materials, you acquire a product with harmless
              manufacturing materials that reduce CO2 emissions, thus promoting
              environmental conservation.
            </p>
          </div>
          <div></div>
          <div className="section-info-p_right">
            <p>
              You promote recycling and the reuse of materials by giving a
              second life to the product. You have the opportunity to explore
              the variety of products we offer, as well as acquire different
              designs, a wide range of colors, and both traditional and
              innovative styles.
              <br />
              <br />
              The energy for the plant is obtained through solar panels,
              generating savings for over 25 years up to the present moment.
              <br />
              <br />
              You acquire a versatile and adaptable product suitable for both
              outdoor and indoor environments, thanks to its high durability and
              ability to withstand various weather conditions.
            </p>
          </div>
        </section>
      </div>
      <section className="section-silla-eco">
        <div className="silla-eco-text">
          <div className="silla-eco-text_top">
            <Cert
              title={"ELIMINATION OF PLASTIC PACKAGING."}
              info={
                "For the packaging of internally manufactured structures, the leftover leather and cardboard are utilized. This eliminates the need for plastic protection and aims to use minimal amounts of biodegradable materials."
              }
            />
          </div>
          <div className="silla-eco-text_mid">
            <Cert
              title={"HEAVY METAL-FREE WASHING SYSTEM"}
              info={
                "The electrostatic painting process in our Metal Mechanics plant is carried out with a chemical treatment using zirconium phosphate, which is free from heavy metals. This treatment enhances the corrosion resistance of the painted structures, ensuring compliance with regulatory standards for water treatment as it does not contaminate the network and prevents harm to ecosystems. Additionally, the generation of sludge is reduced by 95% due to the elimination of heavy metals. This places us at the forefront of quality and environmental care."
              }
            />
          </div>
          <div className="silla-eco-text_bot">
            <Cert
              title={"WATER-BASED ADHESIVE"}
              info={
                "It is implemented as the primary product in upholstery processes, as these adhesives are of excellent quality and suitable for bonding various porous materials. They are also environmentally friendly."
              }
            />
          </div>
        </div>
        <img src={silla} alt="Grupo Requiez - Silla eco" />
      </section>

      <div className="section-i_bottom">
        <p>
          <strong>
            AFTER SALES SERVICE TO INCREASE THE USEFUL LIFE OF OUR PRODUCTS
          </strong>
          <br />
          <br />
          One of the principles of the circular economy is to keep products for
          longer in use, Grupo Requiez, committed to this movement which is
          already part of the corporate philosophy, offers post sale as
          maintenance, reupholstered and sale of spare parts for your products,
          increasing their useful life and thereby reducing the impact
          environmental.
        </p>
      </div>
    </div>
  );
}
