import React from "react";

import Banner from "../../PagesComponents/Banner/Banner";
import TextBox from "../../PagesComponents/TextBox/TextBox";
import Cert from "../../PagesComponents/Cert/Cert";
import SpaceRight from "../../PagesComponents/SpaceRightS/SpaceRight";

import banner from "../../../assets/sustentability/banner.webp";
import product from "../../../assets/sustentability/productos_sustentables.webp";
import silla from "../../../assets/sustentability/silla_eco.png";

import certs from "../../../certs.json";

import "./Sustentability.css";

export default function Sustentability() {
  return (
    <div>
      <Banner img={banner} />

      <div className="sustentability-container">
        <TextBox
          text1="Durante casi 40 años en el mercado hemos
          logrado distinciones de calidad, de alto nivel."
          text2="Esto nos motiva a seguir siendo una marca
          competitiva a nivel nacional."
          color="#FFF"
          colorletter="#000"
        />

        <section className="section-cert">
          {certs.map(({ name, image, info }) => (
            <Cert key={name} name={name} image={image} info={info} />
          ))}
        </section>
        <SpaceRight
          name={"Productos Sustentables"}
          image={product}
          info={
            "Cuando adquieres productos en Grupo Requiez, también estas contribuyendo al cuidado del medio ambiente."
          }
        />
        <section className="section-info-p">
          <div className="section-info-p_left">
            <p>
              Ayudas a crear un mundo más justo y sostenible, evitas la
              deforestación, porque un porcentaje de nuestros productos se
              certifican con la FSC (Forest Stewardship Council o Consejo de
              Administración Forestal).
              <br />
              <br />
              Ayudas a que tu hogar sea más saludable y armonioso, debido a que
              estas desplazando elementos que son nocivos para la salud, al usar
              tapicería y textiles que son de plantaciones ecológicas sin
              tratamientos químicos, además de ser 100% reciclables y
              reciclados, adquieres un producto con fabricación inofensiva por
              sus materiales, que reducen las emisiones de CO2, favoreciendo la
              conservación del medio ambiente.
            </p>
          </div>
          <div></div>
          <div className="section-info-p_right">
            <p>
              Favoreces el reciclaje y la reutilización de materiales brindando
              una segunda vida al producto, cuentas con la oportunidad de ver la
              variedad de productos que manejamos, además de adquirir diseños
              diferentes, variedad de colores y estilos tanto tradicionales como
              innovadores.
              <br />
              <br />
              La energía de la planta se obtiene a través de paneles solares,
              generando un ahorro por más de 25 años hasta el momento.
              <br />
              <br />
              Adquieres un producto versátil y adaptable a ambientes exteriores
              e interiores, debido a su alta resistencia y soporte a las
              inclemencias del tiempo.
            </p>
          </div>
        </section>
      </div>
      <section className="section-silla-eco">
        <div className="silla-eco-text">
          <div className="silla-eco-text_top">
            <Cert
              title={"ELIMINACIÓN DE EMPAQUES DE PLÁSTICO"}
              info={
                "Para el empaque de las estructuras de fabricación interna se utiliza el sobrante de las pieles y cartón, con esto ya no es necesario utilizar protecciones de plástico y buscando que sean utilizados en cantidades mínimas y biodegradables."
              }
            />
          </div>
          <div className="silla-eco-text_mid">
            <Cert
              title={"SISTEMA DE LAVADO LIBRE DE METALES PESADOS"}
              info={
                "El proceso de pintura electrostática en nuestra planta Metal Mecánica, se realiza con un tratamiento químico de lavado libre de metales pesados a base de fosfato de zirconio. Con este tratamiento se incrementa la calidad en la resistencia a la corrosión de las estructuras pintadas, asegurando el cumplimiento de las normas regulatorias para el tratamiento de aguas, ya que no contamina la red y evita la afectación a los ecosistemas, además, la generación de lodo baja en un 95% por la eliminación de metales pesados. Esto nos coloca a la vanguardia en calidad y cuidado del medio ambiente."
              }
            />
          </div>
          <div className="silla-eco-text_bot">
            <Cert
              title={"PEGAMENTO CON BASE AGUA"}
              info={
                "Es implementado como el principal producto en los procesos de tapizado, ya que son adhesivos de una excelente calidad adecuados para unir todo tipo de materiales porosos y son amigables con el medio ambiente."
              }
            />
          </div>
        </div>
        <img src={silla} alt="Grupo Requiez - Silla eco" />
      </section>

      <div className="section-i_bottom">
        <p>
          <strong>
            SERVICIO POST VENTA PARA INCREMENTAR LA VIDA ÚTIL DE NUESTROS
            PRODUCTOS
          </strong>
          <br />
          <br />
          Uno de los principios de economía circular es mantener los productos
          por más tiempo en uso, Grupo Requiez, comprometido con este movimiento
          que ya forma parte de la filosofía corporativa, ofrece servicios post
          venta como mantenimiento, retapizados y venta de refacciones para sus
          productos, incrementando su vida útil y reduciendo con esto el impacto
          ambiental.
        </p>
      </div>
    </div>
  );
}
